import { HttpWrapper } from "../shared/services/rest.service";
import * as endpoints from "./endpoints";

export default {

    getFileScanDocuments (orderId, publishedOnly = false) {
        if (publishedOnly) return HttpWrapper.get(`${endpoints.FILESCAN}/published/${orderId}`, null);
        return HttpWrapper.get(`${endpoints.FILESCAN}/${orderId}`, null);
    },

    getFileScanDocument (fileScanDocumentID, action) {
        return HttpWrapper.get(`${endpoints.FILESCAN}/content/${fileScanDocumentID}/action/${action}`, null);
    },

    getFileScanDocumentData(action, saveAsFileType, fileScanDocumentIDs) {
        return HttpWrapper.post(`${endpoints.FILESCAN}/data/${action}/fileType/${saveAsFileType}`, fileScanDocumentIDs, null);
    },

    deleteFileScanDocuments(ids) {
        return HttpWrapper.post(`${endpoints.FILESCAN}/delete`, ids, null);
    },

    uploadToFileScan (formRequest) {
        return HttpWrapper.put(`${endpoints.FILESCAN}/upload/`, formRequest, null);
    },

    saveFileScanDocument (request) {
        return HttpWrapper.put(`${endpoints.FILESCAN}/saveContent/`, request, null);
    },

    saveChecksToFileScan (request) {
        return HttpWrapper.post(`${endpoints.FILESCAN}/saveChecks/`, request, null);
    },

    saveToFileScan (request) {
        return HttpWrapper.post(`${endpoints.FILESCAN}/save/`, request, null);
    },

    saveFileScanCopyMove (request) {
        return HttpWrapper.post(`${endpoints.FILESCAN}/copyMove/`, request, null);
    },

    saveFileScanAssignTags (request) {
        return HttpWrapper.post(`${endpoints.FILESCAN}/assignTags/`, request, null);
    },

    mergeFileScanDocuments (request) {
        return HttpWrapper.post(`${endpoints.FILESCAN}/merge/`, request, null);
    },

    saveFileScanCategory (categoryID, ids) {
        return HttpWrapper.put(`${endpoints.FILESCAN}/${categoryID}/setCategory/`, ids, null);
    },

    saveFileScanProperties (dto, changes) {
        return HttpWrapper.put(`${endpoints.FILESCAN}/saveFileScanProperties/`, { data: dto, changes}, null);
    },

    searchFileScanDocuments (request) {
        return HttpWrapper.post(`${endpoints.FILESCAN}/search`, request, null);
    },

    processForFileScanDownload (request) {
        return HttpWrapper.post(`${endpoints.FILESCAN}/processDownload/`, request, null);
    },

    getRoleAccessList (orderId) {
        return HttpWrapper.get(`${endpoints.FILESCAN}/roleaccess/${orderId}`);
    },
    // checkFileScanExistingDocuments (request) {
    //     return HttpWrapper.post(`${endpoints.DOCUMENTS}/filescan/verify/`, request, null);
    // },
    // verifyFileScanDownloads (fileScanDocumentIdString) {
    //     return HttpWrapper.get(`${endpoints.FILESCAN}/verify/${fileScanDocumentIdString}`);
    // },

    createShareUrl(id) {
        return HttpWrapper.get(`${endpoints.FILESCAN}/${id}/share`);
    },

};
