import { HttpWrapper } from "../shared/services/rest.service";
import * as endpoints from "./endpoints";

export default {

    getData (ordersID) {
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/data/${ordersID}`, null);
    },

    getEscrowBankChangeData (ordersID) {
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/escrowBankChangeData/${ordersID}`, null);
    },

    saveEscrowBankChange(request) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/escrowBankChange`, { data: request, changes: null }, null);
    },

    ////////////////
    // Checks     //
    ////////////////
    canTransferCheckToFile (checksID, gfNo) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/canTransferCheckToFile/${checksID}`, { data: gfNo, changes: null}, null);
    },

    getCheck (checksID) {
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/check/${checksID}`, null);
    },

    getNextCheckNumber (bankID) {
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/checkNumber/${bankID}`, null);
    },

    getCheckPrintData (ordersID, bankCompanyID) {
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/checkPrintData/${ordersID}/${bankCompanyID}`, null);
    },

    getCheckPayeeCompanyContacts(startDate, endDate) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/checkPayeeCompanyContacts`, {startDate: startDate, endDate: endDate});
    },

    getLossAdvanceLookupData (ordersID, checksID, moneyMovementTypeID) {
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/lossAdvanceLookups/${ordersID}/${checksID}/${moneyMovementTypeID}`, null);
    },

    getRoleData (checksID) {
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/check/${checksID}/role`, null);
    },

    // createCheck (data, changes) {
    //     return HttpWrapper.post(`${endpoints.CHECK_WRITING}/check/`, { data: data, changes: changes}, null);
    // },

    saveCheckPayee (item, changes) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/checkPayee/`, { data: item, changes: changes}, null);
    },

    deleteChecks(ordersID, ids) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/deleteChecks/${ordersID}`, ids, null);
    },

    deleteTransferCheck(ordersID, checksID) {
        return HttpWrapper.delete(`${endpoints.CHECK_WRITING}/deleteTransferCheck/${ordersID}/${checksID}`, null);
    },

    saveCheck (item, changes) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/check/`, { data: item, changes}, null);
    },

    saveCheckToEscrowAccount (item, changes) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/saveCheckToEscrowAccount/`, { data: item, changes}, null);
    },

    saveCheckToFile (checksID, ordersID) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/saveCheckToFile/${checksID}/${ordersID}`, null, null);
    },

    saveCheckToWire (item, changes) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/saveCheckToWire/`, { data: item, changes}, null);
    },

    saveCheckStatus (checksIDs, checkStatus) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/checkStatus/${checkStatus}`, checksIDs, null);
    },

    saveLossAdvance (item, changes) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/saveLossAdvance/`, { data: item, changes: changes}, null);
    },

    saveLossAdvanceRecovery (item, changes) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/saveLossAdvanceRecovery/`, { data: item, changes: changes}, null);
    },

    splitCheck (checksID, checkLines) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/splitCheck/${checksID}`, checkLines, null);
    },

    moveCheck (checksID, checkLines) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/moveCheck/${checksID}`, checkLines, null);
    },

    undoChecks(ordersID, ids) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/undoChecks/${ordersID}`, ids, null);
    },

    undoAllChecks(ordersID) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/undoAllChecks/${ordersID}`, null, null);
    },

    printChecks(ordersID, request) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/printChecks/${ordersID}`, { data: request, changes: null }, null);
    },

    checkNumberInUse(ordersID, checkNumber){
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/checkNumberInUse/order/${ordersID}/checkNumber/${checkNumber}`, null);
    },

    wireNumberInUse(bankCompanyID, wireNumber){
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/wireNumberInUse/wireNumber/${wireNumber}/bankCompany/${bankCompanyID}`, null);
    },

    generateWireNumber(bankCompanyID, checksID) {
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/wireNumber/${checksID}/generate/${bankCompanyID}`, null);
    },

    verifyBankForWire(checksID, request) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/verifyBankForWire/${checksID}`, { data: request, changes: null }, null);
    },

    isPayeeIndividual(checksID) {
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/isPayeeIndividual/${checksID}`, null);
    },
    ////////////////
    // CheckLines //
    ////////////////

    saveConsolidatedAdjustment (item, changes) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/saveConsolidatedAdjustment/`, { data: item, changes}, null);
    },

    saveCheckLine (ordersID, item, changes) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/checkLine/${ordersID}`, { data: item, changes}, null);
    },

    deleteCheckLines(checksID, ids) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/deleteCheckLines/${checksID}`, ids, null);
    },

    ////////////////
    // Deposits   //
    ////////////////
    canTransferDepositToFile (depositID, gfNo) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/canTransferDepositToFile/${depositID}`, { data: gfNo, changes: null}, null);
    },

    saveDeposit (item, changes) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/deposit/`, { data: item, changes}, null);
    },

    deleteDeposits(ordersID, ids) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/deleteDeposits/${ordersID}`, ids, null);
    },

    setReceiptID(depositID) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/setReceiptID/${depositID}`, null, null);
    },

    setReceiptIDs(depositIDs) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/setReceiptIDs/`, depositIDs, null);
    },

    ///////////////////
    // Deposit Lines //
    ///////////////////

    saveDepositLine (ordersID, item, changes) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/depositLine/${ordersID}`, { data: item, changes}, null);
    },

    deleteDepositLines(depositID, ids) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/deleteDepositLines/${depositID}`, ids, null);
    },

    ////////////////
    // Investments//
    ////////////////
    getInvestmentData (ordersID) {
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/investments/${ordersID}`, null);
    },

    saveInvestment (ordersID, item, changes) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/saveInvestment/${ordersID}`, { data: item, changes}, null);
    },

    createInvestmentCheck (investmentID, amount) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/createInvestmentCheck/${investmentID}`, { data: amount, changes: null}, null);
    },

    createInvestmentDeposit (data) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/createInvestmentDeposit`, { data: data, changes: null}, null);
    },

    deleteInvestments(ordersID, ids) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/deleteInvestments/${ordersID}`, ids, null);
    },

    //////////////////////////
    // Investments Ledger //
    //////////////////////////

    saveInvestmentLedger (ordersID, item, changes) {
        return HttpWrapper.put(`${endpoints.CHECK_WRITING}/saveInvestmentLedger/${ordersID}`, { data: item, changes}, null);
    },

    deleteInvestmentLedgers(investmentID, ids) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/deleteInvestmentLedgers/${investmentID}`, ids, null);
    },

    ////////////////
    // Ledger     //
    ////////////////

    deleteLedgers(ordersID, ids) {
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/deleteLedgers/${ordersID}`, ids, null);
    },

    ////////////////////////////////////
    // Receipts and Disbursements     //
    ////////////////////////////////////

    canCreateReceiptsAndDisbursements(ordersID){
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/canCreateReceiptsAndDisbursements/${ordersID}`);
    },

    doCreateReceiptsAndDisbursements(ordersID, selection){
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/doCreateReceiptsAndDisbursements/${ordersID}`, selection, null);
    },

    canDeleteReceiptsAndDisbursements(ordersID){
        return HttpWrapper.get(`${endpoints.CHECK_WRITING}/canDeleteReceiptsAndDisbursements/${ordersID}`);
    },

    doDeleteReceiptsAndDisbursements(ordersID){
        return HttpWrapper.delete(`${endpoints.CHECK_WRITING}/doDeleteReceiptsAndDisbursements/${ordersID}`);
    },

    canCombineFunds(ordersID, selection){
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/canCombineFunds/${ordersID}`, selection, null);
    },

    getSyncData(ordersID, selection){
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/getSyncData/${ordersID}`, selection, null);
    },

    applyFixes(ordersID, fixes){
        return HttpWrapper.post(`${endpoints.CHECK_WRITING}/fix/${ordersID}`, fixes, null);
    }

};
