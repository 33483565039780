import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getFileScanDescriptions(){
        return HttpWrapper.get(`${endpoints.FILESCANDESCRIPTIONS}`);
    },
    saveFileScanDescription (fileScanDescription, changes) {        
        return HttpWrapper.put(`${endpoints.FILESCANDESCRIPTIONS}`, { data: fileScanDescription, changes} );              
    },
    deleteFileScanDescriptions (ids) {              
        return HttpWrapper.post(`${endpoints.FILESCANDESCRIPTIONS}/delete`, ids);
    },
    hasExistingDocuments (ids) {              
        return HttpWrapper.post(`${endpoints.FILESCANDESCRIPTIONS}/hasexistingdocuments`, ids);
    },
    reorderFileScanDescriptions (data) {
        return HttpWrapper.put(`${endpoints.FILESCANDESCRIPTIONS}/reorder`, data);
    },
};